import * as React from 'react'
import { Button } from '@workday/canvas-kit-react'
import Hero from './hero'
import NWConstants from '../../utils/constants'

interface HomeProps {location: object}

class Home extends React.Component<HomeProps> {
	constructor(props: HomeProps) {
		super(props)
	}

	render() {

		return (
			<div>
				<Hero location={this.props.location}/>
				<div className="hero-section">
					<h4>If you have any questions please visit our support site.</h4>
					<p>Learn more at Workday Peakon Employee Voice.</p>
					<a target="_blank" href={NWConstants.PEAKON_SUPPORT_URL}><Button buttonType={Button.Types.Secondary}>Visit Now</Button></a>
				</div>
			</div>
		)
	}
}

export default Home
